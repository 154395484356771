<template>
  <div>
    <loading v-if="isLoading" />
    <error v-else-if="isError" />
    <content-not-view v-else-if="!subPermission.index" />

    <b-card v-else>
      <div class="custom-search d-flex justify-content-between mb-1">
        <!-- search input -->
        <b-form-group>
          <div class="d-flex align-items-center">
            <div>
              <label class="mr-1">{{ $t("g.searchLabel") }}</label>
              <b-form-input
                v-model="searchTerm"
                :placeholder="$t('g.searchHere')"
                type="text"
                class="d-inline-block"
              />
            </div>
          </div>
        </b-form-group>
        <div class="d-flex align-items-center" v-if="subPermission.store">
          <b-button :to="{ name: 'AddServiceRequest' }" variant="primary">{{
            $t("g.AddServiceRequest")
          }}</b-button>
        </div>
      </div>
      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        styleClass="vgt-table condensed"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <!-- Slot: Table Column -->
        <template slot="table-column" slot-scope="props">
          <span v-if="props.column.label === 'client'" class="text-nowrap">
            {{ $t("g.client") }}
          </span>
          <span
            v-else-if="props.column.label === 'serviceType'"
            class="text-nowrap"
          >
            {{ $t("g.serviceType") }}
          </span>
          <span
            v-else-if="props.column.label === 'productName'"
            class="text-nowrap"
          >
            {{ $t("g.productName") }}
          </span>
          <span
            v-else-if="props.column.label === 'serviceCode'"
            class="text-nowrap"
          >
            {{ $t("g.serviceCode") }}
          </span>
          <span v-else-if="props.column.label === 'status'" class="text-nowrap">
            {{ $t("g.status") }}
          </span>
          <span
            v-else-if="props.column.label === 'submitDate'"
            class="text-nowrap"
          >
            {{ $t("g.submitDate") }}
          </span>
          <span
            v-else-if="props.column.label === 'expectedReviewEndDate'"
            class="text-nowrap"
          >
            {{ $t("g.expectedReviewEndDate") }}
          </span>
          <span v-else-if="props.column.label === 'action'" class="text-nowrap">
            {{ $t("g.action") }}
          </span>
          <span v-else>
            {{ props.column.label }}
          </span>
        </template>
        <!-- Slot: Table Row -->
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'submit_date_row'">
            <span>
              {{ props.row.submit_date ? format(props.row.submit_date) : "__" }}
            </span>
          </span>
          <span
            v-else-if="props.column.field === 'excpected_review_end_date_row'"
          >
            <span>
              {{ format(props.row.excpected_review_end_date) }}
            </span>
          </span>
          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                text="Block Level Dropdown Menu"
                block
                class="files-lists"
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>

                <b-dropdown-item
                  v-if="subPermission.show"
                  :to="{
                    name: 'ViewServiceRequest',
                    params: { id: props.row.id },
                  }"
                >
                  <feather-icon icon="EyeIcon" class="mr-50" />
                  <span>{{ $t("g.viewRequest") }}</span>
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="subPermission.update"
                  :to="{
                    name: 'EditServiceRequest',
                    params: { id: props.row.id },
                  }"
                >
                  <feather-icon icon="EditIcon" class="mr-50" />
                  <span>{{ $t("g.edit") }}</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="subPermission.destroy"
                  @click="deleteAlert(props.row.id)"
                >
                  <feather-icon icon="Trash2Icon" class="mr-50" />
                  <span>{{ $t("g.delete") }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                {{ $t("g.pagelength") }}
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['3', '5', '10']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap">
                {{ $t("g.of") }} {{ props.total }}
                {{ $t("g.pageText2") }}
              </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import {
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BCard,
  BDropdownItem,
  BDropdown,
  BButton
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Loading from "@/views/components/logic/loading.vue";
import Error from "@/views/components/logic/error.vue";
import store from "@/store/index";
import formatDate from "@/composables/format-date/format-date";
import ContentNotView from "@/views/components/logic/contentNotView.vue";

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BCard,
    Error,
    Loading,
    BDropdownItem,
    BDropdown,
    ContentNotView,
    BButton
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: "client",
          field: "client.name",
        },
        {
          label: "serviceType",
          field: "service.name",
        },
        {
          label: "productName",
          field: "prodcut_name",
        },
        {
          label: "serviceCode",
          field: "service_code",
        },
        {
          label: "status",
          field: "stauts",
        },
        {
          label: "submitDate",
          field: "submit_date_row",
        },
        {
          label: "expectedReviewEndDate",
          field: "excpected_review_end_date_row",
        },
        {
          label: "action",
          field: "action",
        },
      ],
      rows: [],
      searchTerm: "",
      isLoading: true,
      isError: false,
      format: null,
      subPermission: {},
    };
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  beforeMount() {
    this.format = formatDate;
    this.$http
      .get(`admin/serviceRequests`)
      .then((response) => {
        if (response.status === 200) {
          const data = response.data.data;
          this.rows = data;

          setTimeout(() => {
            this.pageStatus(false, false);
            store.dispatch("GET_PERMISSION", "serviceRequests");

            this.subPermission = store.state.permissions.sub;
          }, 1000);
        }
      })
      .catch((error) => {
        console.log(error);
        this.pageStatus(false, true);
      });
  },

  methods: {
    pageStatus(loading, error) {
      this.isLoading = loading;
      this.isError = error;
    },

    deleteItem(id) {
      this.rows = this.rows.filter((item) => item.id !== id);
    },

    deleteAlert(id) {
      this.$swal({
        title: this.$t("g.areYouSure?"),
        text: this.$t("g.youWontBeAbleToRevertThis"), // You won't be able to revert this!
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("g.yesDeleteIt"), // "Yes, delete it!"
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$http
            .delete(`admin/serviceRequests/${id}`)
            .then((res) => {
              if (res.status === 200) {
                this.deleteItem(id);
                this.$swal({
                  icon: "success",
                  title: this.$t("g.deleted"), //"Deleted!"
                  text: this.$t("g.yourFileHasBeenDeleted"), //"Your file has been deleted."
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.files-lists {
  ul {
    transform: translate3d(-70px, -51px, 0px) !important;
  }
}
</style>
